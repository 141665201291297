<template>
    <page :loading="loading">
        <div slot="head" class="flex-col">
            <!-- <div class="flex-item flex-fill" style="text-align: center;">
                <el-input v-model="table.search.keyword" placeholder="请输入搜索关键字" style="width:300px" @keydown.enter.native="LoadDatas()">
                    <el-button slot="append" icon="el-icon-search" @click="LoadDatas()" />
                </el-input>
            </div> -->
            <div class="flex-item flex-right">
                <el-button type="primary" style="width:100px" @click="table.form = {sort:table.search._count+1,type:0,status:0},$refs['page-dialog'].open()">添加</el-button>
            </div>
        </div>

        <page-table :datas="table.data" :page="table.search._page" :size="table.search._size" :total="table.search._count" @change="LoadDatas">
            <!-- <el-table-column prop="id"                             label="id" align="center" fixed="left"  width="120" /> -->
			<el-table-column prop='sort'                           label='第几关' align='center' />
			<el-table-column :formatter="typeFormat"   		       label='类型' align='center' prop='type'/>
			<el-table-column prop='problem'                        label='问题' align='center' show-overflow-tooltip>
			    <template slot-scope="scope">
			        <el-popover placement="bottom" trigger="click">
			            <a :href="$img(scope.row.problem)" target="_blank" title="查看最大化图片">
			                <img :src="$img(scope.row.problem)" style="width: 100%;max-width:500px" />
			            </a>
			            <img slot="reference" :src="$img(scope.row.problem)" style="width: 40px; cursor:pointer" />
			        </el-popover>
			    </template>
			</el-table-column>
            <el-table-column prop='answer'                         label='答案' align='center' />
            <el-table-column prop='disturb'                        label='干扰选择的文字' align='center' />
            <el-table-column prop='description'                    label='比喻' align='center' />
            <el-table-column :formatter="statusFormat"   		   label='状态' align='center' prop='status'/>
            <el-table-column prop='created_at'                     label='创建时间' align='center' />
            <el-table-column prop='updated_at'                     label='更新时间' align='center' />
            <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="table.form = Object.assign({},scope.row),$refs['page-dialog'].open()" type="text" size="small">编辑</el-button>
                    <el-button @click="ActionDel(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </page-table>

        <page-dialog ref="page-dialog" :loading="loading">
            <el-form ref="form">
				<el-form-item label="第几关">
				    <el-input v-model="table.form.sort" placeholder="请输入第几关" type="number" />
				</el-form-item>
				<el-form-item label="类型">
					<el-select @change="selectType" v-model="table.form.type" placeholder="请选择类型">
						<el-option label="看图猜成语" :value="0" />
						<el-option label="看字猜成语" :value="1" />
					</el-select>
				</el-form-item>
				<el-form-item label="问题" v-if="table.form.type == 0" class="block">
				    <page-images v-model="table.form.problem" :limit="1" />
				</el-form-item>
				<el-form-item label="问题" v-if="table.form.type == 1" class="block">
				    <el-input v-model="table.form.problem" placeholder="请输入答案" />
				</el-form-item>
                <el-form-item label="答案">
                    <el-input v-model="table.form.answer" placeholder="请输入答案" />
                </el-form-item>
                <el-form-item label="干扰选择的文字(需要包括答案在内)">
                    <el-input v-model="table.form.disturb" placeholder="请输入干扰选择的文字" />
                </el-form-item>
                <el-form-item label="比喻">
                    <el-input v-model="table.form.description" placeholder="请输入比喻" />
                </el-form-item>
                <el-form-item label="状态">
                	<el-select v-model="table.form.status" placeholder="请选择状态">
                		<el-option label="正常" :value="0" />
                		<el-option label="禁用" :value="1" />
                	</el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="ActionSave(table.form)">保存</el-button>
            </div>
        </page-dialog>
    </page>
</template>
<script>
export default {
    data(){
        return {
            loading:false,
            table:{
                data:[],
                form:{},
                search:{
                    _page:1,
                    _size:20,
                    _count:0,
                }
            },
			statusList:['正常','禁用'],
			typeList:['看图猜成语','看字猜成语'],
        }
    },
    mounted() {
        this.LoadDatas();
    },
    methods: {
		//状态格式化
		statusFormat(row) {
		  return this.statusList[row.status];
		},
		//类型格式化
		typeFormat(row) {
			return this.typeList[row.type];
		},
		//选择类型
		selectType(value){
			this.table.form.problem = '';
		},
        /**
         * 获取数据列表
         * @param page 分页-当前页
         * @param size 分页-每页数量
         * **/
        LoadDatas(page,size){
            page && (this.table.search._page = page),size && (this.table.search._size = size),this.loading = true;
            this.$get('app-idiom',this.table.search).then(res=>
            {
                this.table.data = res.data , this.table.search._count = res.extra.pageInfo.count , this.loading = false;
            }).catch(err=>
            {
                this.$notify.error({message: err || '网络请求错误'}),this.loading = false;
            });
        },

        /**
         * 保存
         * @param item 要保存的数据
         * **/
        ActionSave(item){
            this.loading = true;
            this.$save('app-idiom',item).then(res=>
            {
                this.loading = false , this.LoadDatas(),this.$notify({message: res.msg || '操作成功'}) , this.$refs['page-dialog'].close();
            }).catch(err=>
            {
                this.loading = false , this.$notify.error({message: err || '网络请求错误'});
            });
        },

        /**
         * 删除
         * @param item 要删除的数据
         * **/
        ActionDel(item){
            if( item.id ){
                this.loading = true;
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
                    this.$del('app-idiom/'+item.id).then(res=>{
                        this.loading = false , this.LoadDatas() , this.$notify({message: res.msg || '操作成功'});
                    }).catch(err=>{
                        this.loading = false , this.$notify.error({message:err||'删除失败'});
                    })
                }).catch(()=>{
                    this.loading = false;
                })
            }
        }
    },
}
</script>